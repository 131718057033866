import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";


export default function ContactPage() {
    return(
        <div className="contact-page">
            <div className="row mt-5 mb-5 d-flex justify-content-around">
                <div className="col-md-6 shadow">
                    <h4>Send as an email on:</h4>
                    <p>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <a
                            href="mailto:georgkingsworldtourstravellers@gmail.com"
                            className="ms-3 text-decoration-none"
                        >
                          Click here
                        </a>
                    </p>
                </div>
                <div className="col-md-6 shadow">
                    <h4>Call/Whatsapp Us on:</h4>
                    <p>
                        <FontAwesomeIcon icon={faPhone} />
                        <a
                          href="phone:+254715529912"
                          className="ms-3 text-decoration-none"
                        >
                            +254715529912
                        </a>
                    </p> <br/>
                    <p>
                        <FontAwesomeIcon icon={faPhone} style={{ color: "white" }} />
                        <a
                            href="phone:+971588399215"
                            className="ms-3 te text-decoration-none"
                        >
                            +971588399215
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}